import { useEffect } from 'react'
import useGetPriceData from './useGetPriceData' 

const useGetDocumentTitlePrice = () => {
  const jandraPriceUsd = useGetPriceData()

  const jandraPriceUsdString =
    Number.isNaN(jandraPriceUsd) || jandraPriceUsd === 0
      ? ''
      : `$${jandraPriceUsd.toLocaleString(undefined, {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        })}`

  useEffect(() => {
    document.title = `${jandraPriceUsdString} - socialbureau.finance`
  }, [jandraPriceUsdString])
}
export default useGetDocumentTitlePrice
