import { createReducer } from '@reduxjs/toolkit'
import {
  updateJandraPriceUSD
} from './actions'


export interface PriceState {
  jandraPriceUSD: number
}

export const initialState: PriceState = {
  jandraPriceUSD: 0,
}

export default createReducer(initialState, builder =>
  builder
    .addCase(updateJandraPriceUSD, (state, action) => {
      state.jandraPriceUSD = action.payload.jandraPriceUSD
    })
)