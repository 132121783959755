import { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useDispatch } from 'react-redux'
import useRefresh from './useRefresh'
import { getCakeAddress } from 'utils/addressHelpers'
import { updateJandraPriceUSD } from 'state/price/actions'
import { useJUTCUsd } from './useContract'
import contracts from 'constants/contracts'

const chainId = process.env.REACT_APP_CHAIN_ID || '97'

const useGetPriceData = () => {
  const dispatch = useDispatch()
  const { fastRefresh } = useRefresh()
  const jandraRouter = useJUTCUsd()

  const [jandraPriceUsd, setjandraPriceUsd] = useState(0)
  const oneBnAmount = new BigNumber(10).pow(18)

  useEffect(() => {
    const fetchUsdPrice = async (amount: string, tokenInOutAddress: string[]) => {

      const result = await jandraRouter.methods.getAmountsOut(amount, tokenInOutAddress).call()
        .then((res) =>
          new BigNumber(res[1])
        )
        .then((pricesOut) => pricesOut)
      setjandraPriceUsd(result.dividedBy(new BigNumber(10).pow(18)).toNumber())
    }

    fetchUsdPrice(oneBnAmount.toString(10), [getCakeAddress(), contracts.busd[chainId]])
    dispatch(updateJandraPriceUSD({ jandraPriceUSD: jandraPriceUsd }))

  }, [dispatch, fastRefresh, jandraRouter.methods, oneBnAmount, jandraPriceUsd])

  return jandraPriceUsd
}

export default useGetPriceData
